<!-- Modal to post a group of batches together -->
<template>
  <v-dialog justify="center" :value="value" persistent max-width="400">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="blue">mdi-arrow-up-bold-box</v-icon>
        </v-row>
        <v-row justify="center">
            <span class='pa-4'>
            Post Selected Batch?
            </span>
            <span class='pa-4'>
            Warning: This action cannot be undone.
            </span>
        </v-row>
        <v-row>
            <v-col>
              <v-btn text depressed class="primary--text" @click="addNote">
                <v-icon left>mdi-plus</v-icon>Add Note
              </v-btn>
              <v-text-field
              v-if="noteStatus"
              label="Optional Note"
              v-model="payload.note"
              :rules="[v => (v || '' ).length <= 2000 || 'Note must be 2000 characters or less']"
              height="80px"
              outlined
              dense>
              </v-text-field>
            </v-col>
          </v-row>
        <v-row justify="center" class="pa-4">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            No
          </v-btn>
          <v-btn color="#0c67a5" height="40" class="white--text ml-2"
            @click="postBatches">Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import BillingBatch from '@/axios/billing-batch'
export default {
  name: 'PostBatch',

  props: [
    'value',
    'selectedBatches'
  ],

  data() {
      return {
        payload: {'ids':[], 'note':''},
        batch: {},
        filteredBatches:[],
        noteStatus: false
      }
  },
  created() {
      this.payload = {'ids':[], 'note':''}
      this.filterBatches()
  },
  methods: {
    addNote () {
      this.noteStatus = true
    },
    async postBatches () {
        await BillingBatch.postBatches(this.payload).then(() => {
        this.$emit('update:value', false)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Batches Posted Successfully` })
        }).catch ( err =>{
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Post Batches on ${err}` })
        })
      },
    filterBatches() {
        this.filteredBatches = this.selectedBatches.filter(batch => batch.status === 'COMPLETE');
        for (let batch of this.filteredBatches){
            this.payload.ids.push(batch.id)
        }
    }
      

    }
  
}
</script>